import flatpickr from "flatpickr";
import "flatpickr/dist/l10n/pt";

export default function() {

  document.querySelectorAll('.datepicker').forEach(e => {
    const min = e.dataset.minDate;
    const max = e.dataset.minDate;
    const modal = 'static' in e.dataset;

    flatpickr(e, {
      dateFormat: "d/m/Y",
      locale: "pt",
      minDate: min,
      maxDate: max,
      static: modal
    })

  })

}
