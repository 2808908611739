// import { Controller } from "stimulus"
// import "../vendor/ckeditor"

// export default class extends Controller {
//   static targets = [ "input" ]
//   static values = { toolbar: String }

//   static toolbar_full = [
// 		'heading',
// 		'|',
// 		'bold',
// 		'italic',
// 		'strikethrough',
// 		'highlight:yellowMarker',
// 		'code',
// 		'link',
// 		'|',
// 		'alignment',
// 		'outdent',
// 		'indent',
// 		'blockQuote',
// 		'|',
// 		'bulletedList',
// 		'numberedList',
// 		'|',
// 		'imageUpload',
// 		'insertTable',
// 		'mediaEmbed',
// 		'|',
// 		'removeFormat',
// 		'undo',
// 		'redo'
// 	]

//   static toolbar_simple = [
//   	'bold',
//   	'italic',
//   	'strikethrough',
//   	'code',
//   	'link',
//   	'|',
//   	'bulletedList',
//   	'numberedList',
//   	'|',
//   	'removeFormat',
//   	'undo',
//   	'redo'
//   ]

// //   static toolbar_odf = [
// //     'bold',
// //   	'italic',
// //   	'underline',
// //   	'|',
// //   	'blockQuote',
// //   	'|',
// //   	'removeFormat',
// //   	'undo',
// //   	'redo'
// //   ]

//   connect() {
//     // var bodyURL = this.data.get('url')

//     // if(bodyURL) {
//     //   this.inputTarget.innerHTML = 'Carregando...'
//     //   $.get(
//     //     { url: this.data.get('url') }
//     //   ).done(
//     //     (response) => this.setupEditor(response)
//     //   )
//     // } else  {
//     //   this.setupEditor(null)
//     // }

//     this.setupEditor(null)
//   }

//   setupEditor(initialData) {
//     var editor_options = {
//       toolbar: {
//         items: toolbar,
//         viewportTopOffset: 107
//       },
//       mediaEmbed: {
//         previewsInData: true
//       }
//     }

//     if (this.hasToolbarValue &&  this.toolbarValue == 'full') {
//       editor_options.toolbar.items = this.constructor.toolbar_full
//     } else {
//       editor_options.toolbar.items = this.constructor.toolbar_simple
//     }

//     if(!(initialData == null)) {
//       editor_options.initialData = initialData
//     }

//     ClassicEditor
//       .create( this.inputTarget, editor_options )
//       .then( editor => {
//           // console.log( editor );
//       } )
//       .catch( error => {
//           console.error( error );
//       } )
//   }

// }
