import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "field", "toggle" ]


  connect() {
    if (this.hasFieldTarget) {

      $(this.fieldTarget).on('change', (e) => {
        this.onChangeValue(this.fieldTarget.value)
      })

      this.onChangeValue(this.fieldTarget.value)

    } else if (this.data.has('initial-value')) {
      this.onChangeValue(this.data.get('initial-value'))
    }
  }

  onChangeValue(value) {
    this.value = value
    this.toggleTargets.forEach(t => this.toggle(t))
  }

  change(event) {
    this.onChangeValue(event.target.value)
  }

  toggle(t) {
    const showToggle = (this.data.has('boolean') && this.fieldTarget.checked) || // field é um checkBox
                        this.includeValue(this.data.get('show-value')) ||        // show-value geral, definido no controller
                        this.includeValue(t.dataset.showValue) ||                // show-value específico, definido no proprio toggleTarget
                        this.notIncludeValue(this.data.get('hide-value')) ||     // hide-value geral, definido no controller
                        this.notIncludeValue(t.dataset.hideValue)                // hide-value específico, definido no proprio toggleTarget

    if (showToggle) {
      this.show(t)
    } else {
      this.hide(t)
    }
  }

  show(el) { el.style.display = 'block'; }
  hide(el) { el.style.display = 'none'; }

  includeValue(where) {
    return where && where.split('|').includes(this.value)
  }

  notIncludeValue(where) {
    return where && ! where.split('|').includes(this.value)
  }

}

// <div data-controller="conditional-toggle" data-conditional-toggle-boolean="true">
//
//   <%= f.input :contrato, as: :boolean, wrapper: :vertical_form,
//                          label: false,
//                          input_html: {
//                            data: { target: 'conditional-toggle.field' }
//                          } %>
//
//   <div class="row" data-target="conditional-toggle.toggle">
//     <%= f.input :numero_contrato %>
//   </div>
//
// </div>
