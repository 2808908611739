import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "main", "check" ]

  connect() {
    this.mainTarget.addEventListener('change', e => this.toggle())
  }

  toggle() {
    this.checkTargets.forEach(t => {
      if (!t.disabled)
        t.checked = this.mainTarget.checked
    })
  }

}
