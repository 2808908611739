// import { Controller } from "stimulus"
// import Sortable from "sortablejs"

// export default class extends Controller {
//   static targets = [ "button" ]
//   static values = { url: String }

//   connect() {
//     new Sortable(this.element, {
//       handle: '.handle',
//       onSort: (ev) => {
//         console.log(ev)
//         this.onSort(ev.item.dataset.itemId, ev.newIndex)
//       }
//     })
//   }

//   onSort(local, position) {
//     var formData = new FormData()
//     formData.append('item_id', local)
//     formData.append('position', position)

//     fetch(this.urlValue, {
//       method: 'POST',
//       headers: { 'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content },
//       body: formData
//     })
//     .then(this.done())
//     .catch(error => this.fail(error))

//   }

//   done() {
//   }

//   fail(error) { alert("Erro na Ordenação:" + error) }

// }
