import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "form", "input", "select2" ]

  connect() {
    this.inputTargets.forEach(t => {
      t.addEventListener('change', e => this.formTarget.submit())
      // t.addEventListener('change', e => console.log(e))
    })

    this.select2Targets.forEach(t => {
      $(t).on('select2:select', e => this.formTarget.submit())
      $(t).on('select2:clear', e => this.formTarget.submit())
    })

  }

}
