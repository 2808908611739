import "bootstrap";
import { initApp } from './index'

$(document).on("click", "[data-behavior~=load-modal]", function(ev) {
  ev.preventDefault();

  const size = this.getAttribute("data-modal-size")
  if (size) {
    $("#global_modal .modal-dialog")
      .removeClass('modal-lg modal-sm')
      .addClass(`modal-${size}`);
  }

  const url = this.getAttribute("href") || this.getAttribute("data-url");
  $("#global_modal .modal-content").load(url, initModals);

  $("#global_modal").modal("show");
});


const initModals = function() {
  initApp();
};

window.globalModal = (content) => {
  $("#global_modal .modal-content").html(content);
  initModals();
};