import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "parent", "child" ]

  connect() {

    $(this.parentTarget).on('change', (e) => {
      if (this.selectedParent) {
        this.childTargets.forEach(t => this.fetchChildren(t))
      } else {
        this.childTargets.forEach(t => this.clearItens(t))
      }
    })

    if (this.selectedParent) this.childTargets.forEach(t => this.fetchChildren(t))

  }

  fetchChildren(target) {
    $.get({
      url: target.dataset.url,
      data: { parent_id: this.selectedParent }
    })
    .done( (response) => this.setItens(response, target) )
  }

  setItens(itens, target) {
    const options = itens.map(item => new Option(item.nome, item.id, false, false))
    target.innerHTML = ""
    $(target).append(options).trigger('change');

    if (itens.length == 1) {
      $(target).val(itens[0].id).trigger('change');
    } else {
      $(target).val(target.dataset.preSelected).trigger('change');
    }
  }

  clearItens(target) {
    target.innerHTML = "";
    $(target).val(null).trigger('change');
  }

  get selectedParent() {
    return this.parentTarget.value
  }

}
